export default {
  data() {
    return {
      rotatesvg: require('@/assets/image/svg/rotate.svg'),
      selectorParentGroup: {},
      selectorGripsGroup: {},
      selectorGrips: {
        nw: null,
        n: null,
        ne: null,
        e: null,
        se: null,
        s: null,
        sw: null,
        w: null
      },
      rotateGrips: {
        nw: null,
        ne: null,
        se: null,
        sw: null
      },
      editBox: {
        //编辑框
        downTarget: null,
        sX: 0,
        SY: 0,
        size: {},
        mState: false, //
        selectBox: null,
        isVal: true
      },
      editPoint: ['a_n', 'a_s', 'a_w', 'a_e', 'a_ne', 'a_sw', 'a_nw', 'a_se'],
      rotateGripConnector: {},
      rotateGrip: {},
      selectType: '',
      oldAngle: true,
      oldAngleVal: 0,
      doc: null
    }
  },
  mounted() {
    this.$eventBus.$on('updataSelectB', data => {
      this.updataSelectB()
    })
  },
  methods: {
    xf(length, offsetX, offsetY) {
      if (length > 1) {
        let zoom = this.stationObj.zoom
        let px = this.drawLine[length - 2].split(' ')[0]
        let py = this.drawLine[length - 2].split(' ')[1]
        let exitsNowX = Math.abs(px - offsetX) * zoom
        let exitsNowY = Math.abs(py - offsetY) * zoom
        if (exitsNowX < 11) {
          offsetX = px
          this.$refs.guidey_dom.style.setProperty(
            'left',
            offsetX * zoom - 1 / this.scaleValue + 22 + 'px'
          )
          this.$refs.guidey_dom.style.display = 'inline'
        } else {
          this.$refs.guidey_dom.style.display = 'none'
        }

        if (exitsNowY < 11) {
          offsetY = py
          this.$refs.guidex_dom.style.setProperty(
            'top',
            offsetY * zoom - 1 / this.scaleValue + 20 + 'px'
          )
          this.$refs.guidex_dom.style.display = 'inline'
        } else {
          this.$refs.guidex_dom.style.display = 'none'
        }
      }
      this.drawLine[length - 1] = offsetX + ' ' + offsetY
    },
    setNewPath(offsetX, offsetY) {
      let str = ''
      let strp = ''
      let length = this.drawLine.length
      if (offsetX && offsetY) {
        this.xf(length, offsetX, offsetY)
        length += 1
      }

      for (let i = 0; i < length - 1; i++) {
        let item = this.drawLine[i]
        if (i == 0) {
          str += 'M' + item
        } else {
          //  M0,0 C33 33,50 50,33 67 L0 100
          if (i < length - 2) {
            let x = Number(this.drawLine[i - 1].split(' ')[0])
            let y = Number(this.drawLine[i - 1].split(' ')[1])
            let cx = Number(this.drawLine[i].split(' ')[0])
            let cy = Number(this.drawLine[i].split(' ')[1])
            let zx = Number(this.drawLine[i + 1].split(' ')[0])
            let zy = Number(this.drawLine[i + 1].split(' ')[1])

            let a = Math.atan2(Math.abs(cy - y), Math.abs(cx - x))
            let a1 = Math.atan2(Math.abs(zy - cy), Math.abs(zx - cx))
            let radius = 20

            let cosa = -Math.cos(a) * radius
            let sina = -Math.sin(a) * radius
            if (cx < x) {
              cosa = Math.cos(a) * radius
            }
            if (cy < y) {
              sina = Math.sin(a) * radius
            }
            let new_x = Number(cx) + cosa
            let new_y = Number(cy) + sina

            let cosa1 = Math.cos(a1) * radius
            let sina1 = Math.sin(a1) * radius
            if (cx > zx) {
              cosa1 = -Math.cos(a1) * radius
            }
            if (cy > zy) {
              sina1 = -Math.sin(a1) * radius
            }

            let new_x2 = Number(cx) + cosa1
            let new_y2 = Number(cy) + sina1
            let pp = new_x + ' ' + new_y
            let cp = item
            let np = new_x2 + ' ' + new_y2
            str += ' L' + pp
            str += ' C' + pp + ',' + item + ',' + np
            strp += ' M' + pp
            strp += ' C' + pp + ',' + item + ',' + np
          } else {
            str += ' L' + item
          }
        }
      }

      this.lineObj.extend_attr.path = str
      this.lineObj.extend_attr.pathP = strp
      this.lineObj.extend_attr.pathS = this.drawLine
    },
    imageToBase64(url) {  
      // 创建新的Image对象  
      return new Promise((resolve, reject) => {
         var Img = new Image(),
          dataURL = '';
          Img.src = url + '?v=' + Math.random();
          Img.setAttribute('crossOrigin', 'Anonymous');
          Img.onload = function() {
            var canvas = document.createElement('canvas'),
              width = Img.width,
              height = Img.height;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/png');
            resolve(dataURL)
          }; 
      })     
    },
  async converDownload(type, name) { 
      let that = this
      if (this.selectorGripsGroup && this.selectorGripsGroup.setAttribute) {
        this.selectorParentGroup.setAttribute('display', 'none')
      }
      var mainWarp = document.getElementById('svgMain')
      var serializer = new XMLSerializer()
      // 先把svg转一下，然后删除关闭按钮
      var svgMainString = serializer.serializeToString(mainWarp)
      var parser = new DOMParser()
      var svgMainElement = parser.parseFromString(svgMainString, 'image/svg+xml').documentElement
      var svgCloseGroup = svgMainElement.querySelectorAll('#svg_close_group')
      var svgBgRect = svgMainElement.querySelectorAll('#svg_bg')
      // 移除删除按钮
      svgCloseGroup.forEach(function(element) {
        element.remove()
      })
      // 给组、撬的正文背景宽度-30
      svgBgRect.forEach(function(rectElement) {
        var rectWidth = rectElement.getAttribute('width')
        var newWidth = parseInt(rectWidth) - 30
        rectElement.setAttribute('width', newWidth)
      })

      var source = serializer.serializeToString(svgMainElement)
      // let name = '此处是文件名'
      if (type == 'SVG') {
        var blob = new Blob([source])
        var a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = name + '.svg'
        a.click()
      } else if (type == 'JSON') {
        let str = JSON.stringify(this.svgLists)
        var blob = new Blob([str])
        var a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = name + '.json'
        a.click()
      } else {
        var images = svgMainElement.getElementsByTagName('image')
        for(let i=0;i<images.length;i++){
          let url=images[i].getAttribute('href')    
          let src=await this.imageToBase64(url)
          images[i].setAttribute('href',src) 
        }
        var source2 = serializer.serializeToString(svgMainElement)
        var path = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(source2)))
        var image = new Image()
        image.src = path
        image.onload = function() {
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          let url = canvas.toDataURL('image/jpg') //得到图片的base64编码数据
          let a = document.createElement('a') // 生成一个a元素
          let event = new MouseEvent('click') // 创建一个单击事件
          a.download = name // 设置图片名称
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
        }
      }
      that.$message.success('导出成功')
    },

    mouseDownEvent(evt) {
      this.editBox.downTarget = evt.target

      this.selectType = ''
      if (this.editPoint.indexOf(evt.target.id) != -1) {
        this.editBox.sX = evt.offsetX
        this.editBox.sY = evt.offsetY
        this.editBox.isVal = true
        this.editBox.mState = true
        let doc = document.getElementById(this.$parent.selectSvgInfo.id)
        this.doc = doc
        this.editBox.size = {
          w: doc.getBBox().width,
          h: doc.getBBox().height,
          sx: this.$parent.selectSvgInfo.sizeW - 1,
          sy: this.$parent.selectSvgInfo.sizeH - 1,
          x: doc.transform.baseVal.getItem(0).matrix.e || 0,
          y: doc.transform.baseVal.getItem(0).matrix.f || 0
        }
      } else if (evt.target.id == 'selectorGrip_rotate') {
        this.editBox.sX = evt.offsetX
        this.editBox.sY = evt.offsetY
        let doc = document.getElementById(this.$parent.selectSvgInfo.id)
        this.doc = doc
        this.editBox.size = {
          w: (doc.getBBox().width * this.$parent.selectSvgInfo.sizeW) / 2,
          h: (doc.getBBox().height * this.$parent.selectSvgInfo.sizeH) / 2,
          sx: this.$parent.selectSvgInfo.sizeW - 1,
          sy: this.$parent.selectSvgInfo.sizeH - 1,
          x: doc.transform.baseVal.getItem(0).matrix.e || 0,
          y: doc.transform.baseVal.getItem(0).matrix.f || 0
        }
        console.log(this.editBox.size)
        this.selectType = 'rotate'
        this.oldAngle = true
        this.oldAngleVal = 0
      } else if (evt.target.id == 'legend') {
        this.selectType = 'legend'
        this.editBox.sX = evt.offsetX
        this.editBox.sY = evt.offsetY
        this.editBox.size = {
          x: this.stationObj.legendx,
          y: this.stationObj.legendy
        }
        this.oldAngle = true
        this.clickType = ''
      } else if (evt.target.id == 'svgMain') {
        this.selectType = 'svgMain'
        this.editBox.sX = evt.offsetX
        this.editBox.sY = evt.offsetY
        this.editBox.size = {
          x: document.getElementById('con').scrollLeft
          //     y: document.getElementById('con').scrollTop
        }
        this.oldAngle = true
      }
    },
    transformPoint(x, y, m) {
      return { x: m.a * x + m.c * y + m.e, y: m.b * x + m.d * y + m.f }
    },
    rotate(x, y) {
      let box = this.editBox.size
      let cx = box.x * this.stationObj.zoom + box.w * this.stationObj.zoom
      let cy = box.y * this.stationObj.zoom + box.h * this.stationObj.zoom

      let angle = Math.atan2(cy - y, cx - x) * (180 / Math.PI) - 90
      angle = angle < -180 ? 360 + angle : angle
      this.$parent.selectSvgInfo.angle = angle.toFixed(2)
      this.updataSelectB()
    },
    mouseMoveEvent(evt) {
      let _this = this

      if (this.editBox.mState && _this.selectSvg && _this.svgLists.length > 0) {
        let selectSvgInfo = this.$parent.selectSvgInfo
        let angle = selectSvgInfo.angle
        let doc = document.getElementById(this.$parent.selectSvgInfo.id)

        let left = selectSvgInfo.svgPositionX
        let top = selectSvgInfo.svgPositionY
        let width = doc.getBBox().width * this.stationObj.zoom
        let height = doc.getBBox().height * this.stationObj.zoom
        //console.log(doc.getBBox())

        let dx = evt.offsetX - this.editBox.sX //*this.stationObj.zoom
        let dy = evt.offsetY - this.editBox.sY //*this.stationObj.zoom

        if (angle) {
          const r = Math.sqrt(dx * dx + dy * dy)
          const theta = Math.atan2(dy, dx) - (angle * Math.PI) / 180.0
          dx = r * Math.cos(theta)
          dy = r * Math.sin(theta)
        }

        let id = this.editBox.downTarget.id
        if (!id.includes('n') && !id.includes('s')) {
          dy = 0
        }
        if (!id.includes('e') && !id.includes('w')) {
          dx = 0
        }

        let // ts = null,
          tx = 0
        let ty = 0
        let sx = width ? this.editBox.size.sx + (width + dx) / width : 1
        let sy = height ? this.editBox.size.sy + (height + dy) / height : 1
        // let sy = height ? (height + dy) / height : 1;
        // let sx = width ? (width + dx) / width : 1;
        // if we are dragging on the north side, then adjust the scale factor and ty
        if (id.includes('n')) {
          sy = height ? this.editBox.size.sy + (height - dy) / height : 1
          ty = -1
        }

        // if we dragging on the east side, then adjust the scale factor and tx
        if (id.includes('w')) {
          sx = width ? this.editBox.size.sx + (width - dx) / width : 1
          tx = -1
        }

        if (evt.shiftKey) {
          let ox = sx.toFixed(2)
          let oy = sy.toFixed(2)
          let s = oy
          if (dy == 0) {
            s = ox
          }
          this.$parent.selectSvgInfo.sizeW = s
          this.$parent.selectSvgInfo.sizeH = s
          this.$parent.selectSvgInfo.svgPositionX = this.editBox.size.x - dx * tx
          this.$parent.selectSvgInfo.svgPositionY = this.editBox.size.y - dy * ty
        } else {
          this.$parent.selectSvgInfo.sizeW = sx.toFixed(2)
          this.$parent.selectSvgInfo.sizeH = sy.toFixed(2)
          this.$parent.selectSvgInfo.svgPositionX = this.editBox.size.x - dx * tx
          this.$parent.selectSvgInfo.svgPositionY = this.editBox.size.y - dy * ty
        }

        this.updataSelectB()
      } else if (this.selectType == 'rotate') {
        this.rotate(evt.offsetX, evt.offsetY)
      } else if (this.selectType == 'legend') {
        let dx = evt.offsetX - this.editBox.sX //*this.stationObj.zoom
        let dy = evt.offsetY - this.editBox.sY //*this.stationObj.zoom

        dx += this.editBox.size.x
        dy += this.editBox.size.y
        this.stationObj.legendx = dx
        this.stationObj.legendy = dy
      } else if (!this.keydown && this.selectType == 'svgMain') {
        let dx = evt.offsetX - this.editBox.sX //*this.stationObj.zoom
        //    let dy = evt.offsetY - this.editBox.sY

        dx += this.editBox.size.x
        //   dy += this.editBox.size.y

        document.getElementById('con').scrollLeft = dx
        //  document.getElementById('con').scrollTop = dy
      } else if (this.selectBoxall.state && this.clickType != 'click') {
        //选择
        let zoom = this.stationObj.zoom
        let w = evt.offsetX - this.mouseInfo.mPositionX
        let h = evt.offsetY - this.mouseInfo.mPositionY

        if (w < 0) {
          this.selectBoxall.x = evt.offsetX / zoom
          w = Math.abs(w)
        } else {
          this.selectBoxall.x = this.mouseInfo.mPositionX / zoom
        }
        if (h < 0) {
          this.selectBoxall.y = evt.offsetY / zoom
          h = Math.abs(h)
        } else {
          this.selectBoxall.y = this.mouseInfo.mPositionY / zoom
        }
        this.selectBoxall.w = w / zoom
        this.selectBoxall.h = h / zoom
      } else if (
        this.containArr.length > 0 &&
        this.selectBoxall.drag &&
        this.clickType != 'click'
      ) {
        let dx = evt.offsetX - this.mouseInfo.mPositionX
        let dy = evt.offsetY - this.mouseInfo.mPositionY
        let zoom = this.stationObj.zoom
        this.mouseInfo.mPositionX = evt.offsetX
        this.mouseInfo.mPositionY = evt.offsetY
        this.containArr.forEach(item => {
          this.svgLists.forEach(item2 => {
            if (item.id == item2.id) {
              item2.svgPositionX = item2.svgPositionX + dx / zoom
              item2.svgPositionY = item2.svgPositionY + dy / zoom
            }
          })
        })
      }
    },
    mouseUpEvent() {
      this.editBox.mState = false
      this.selectType = ''
    },
    transformBox(l, t, w, h, m) {
      var topleft = {
          x: l,
          y: t
        },
        topright = {
          x: l + w,
          y: t
        },
        botright = {
          x: l + w,
          y: t + h
        },
        botleft = {
          x: l,
          y: t + h
        }
      var transformPoint = this.transformPoint
      topleft = transformPoint(topleft.x, topleft.y, m)
      var minx = topleft.x,
        maxx = topleft.x,
        miny = topleft.y,
        maxy = topleft.y
      topright = transformPoint(topright.x, topright.y, m)
      minx = Math.min(minx, topright.x)
      maxx = Math.max(maxx, topright.x)
      miny = Math.min(miny, topright.y)
      maxy = Math.max(maxy, topright.y)
      botleft = transformPoint(botleft.x, botleft.y, m)
      minx = Math.min(minx, botleft.x)
      maxx = Math.max(maxx, botleft.x)
      miny = Math.min(miny, botleft.y)
      maxy = Math.max(maxy, botleft.y)
      botright = transformPoint(botright.x, botright.y, m)
      minx = Math.min(minx, botright.x)
      maxx = Math.max(maxx, botright.x)
      miny = Math.min(miny, botright.y)
      maxy = Math.max(maxy, botright.y)

      return {
        tl: topleft,
        tr: topright,
        bl: botleft,
        br: botright,
        aabox: {
          x: minx,
          y: miny,
          width: maxx - minx,
          height: maxy - miny
        }
      }
    },
    transformListToTransform(tlist, min, max) {
      let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      if (!tlist) {
        // Or should tlist = null have been prevented before this?
        return svg.createSVGTransformFromMatrix(svg.createSVGMatrix())
      }
      min = min || 0
      max = max || tlist.numberOfItems - 1
      min = Number.parseInt(min)
      max = Number.parseInt(max)
      if (min > max) {
        const temp = max
        max = min
        min = temp
      }
      let m = svg.createSVGMatrix()
      for (let i = min; i <= max; ++i) {
        // if our indices are out of range, just use a harmless identity matrix
        const mtom =
          i >= 0 && i < tlist.numberOfItems ? tlist.getItem(i).matrix : svg.createSVGMatrix()
        m = this.matrixMultiply(m, mtom)
      }
      return svg.createSVGTransformFromMatrix(m)
    },
    matrixMultiply(...args) {
      const m = args.reduceRight((prev, m1) => {
        return m1.multiply(prev)
      })
      let NEAR_ZERO = 5e-6 // 0.000005, Firefox fails at higher levels of precision.
      if (Math.abs(m.a) < NEAR_ZERO) {
        m.a = 0
      }
      if (Math.abs(m.b) < NEAR_ZERO) {
        m.b = 0
      }
      if (Math.abs(m.c) < NEAR_ZERO) {
        m.c = 0
      }
      if (Math.abs(m.d) < NEAR_ZERO) {
        m.d = 0
      }
      if (Math.abs(m.e) < NEAR_ZERO) {
        m.e = 0
      }
      if (Math.abs(m.f) < NEAR_ZERO) {
        m.f = 0
      }

      return m
    },
    deleteEit() {
      if (this.selectorParentGroup && this.selectorParentGroup.id) {
        this.selectorParentGroup.setAttribute('display', 'none')
      }
    },
    changeList() {
      let val = this.svgLists

      //  if(this.history.alone){
      if (this.history.allow) {
        if (this.history.list.length > this.history.index) {
          this.history.list.splice(this.history.index, this.history.list.length - 1)
        }
        if (this.history.list.length > 20) {
          //最多20步
          this.history.list.splice(0, 1)
        }
        this.history.list.push(JSON.parse(JSON.stringify(val)))
        this.history.index = this.history.list.length
      }
      this.history.allow = true
      //}
      // this.history.alone = true
    },

    /*
   编辑状态
  */

    setEditxy(obj, size) {
      let sizeH = obj.sizeH
      let sizeW = obj.sizeW
      let x = obj.svgPositionX
      let y = obj.svgPositionY
      let w = size.w * sizeW
      let h = size.h * sizeH
      let angle = obj.angle

      let f = 4 / this.stationObj.zoom

      // if (angle) {
      //   let doc = document.getElementById(this.$parent.selectSvgInfo.id)
      //   this.doc=doc
      //   const rotm = this.transformListToTransform(this.doc.transform.baseVal).matrix
      //   const nbox = this.transformBox(x, y, w , h , rotm);
      //   // rot.matrix;
      //   let tl = this.transformPoint(nbox.tl.x, nbox.tl.y, rotm);
      //   // calculate the axis-aligned bbox
      // //  const { tl } = nbox;
      //   let minx = tl.x;
      //   let miny = tl.y;
      //   let maxx = tl.x;
      //   let maxy = tl.y;

      //   const { min, max } = Math;

      //   minx = min(minx, min(nbox.tr.x, min(nbox.bl.x, nbox.br.x)));
      //   miny = min(miny, min(nbox.tr.y, min(nbox.bl.y, nbox.br.y)));
      //   maxx = max(maxx, max(nbox.tr.x, max(nbox.bl.x, nbox.br.x)));
      //   maxy = max(maxy, max(nbox.tr.y, max(nbox.bl.y, nbox.br.y)));
      //   console.log(minx)
      //   console.log(maxy)

      //   x = minx;
      //   y = miny;

      // }

      let gipsMath = {
        nw: [x - f, y - f],
        ne: [x + w - f, y - f],
        sw: [x - f, y + h - f],
        se: [x + w - f, y + h - f],
        n: [x + w / 2 - f, y - f],
        w: [x - f, y + h / 2 - f],
        e: [x + w - f, y + h / 2 - f],
        s: [x + w / 2 - f, y + h - f]
      }

      // let gipsMath = {
      //   nw: [x - w / 2 - f, y - h / 2 - f],
      //   ne: [x + w / 2 - f, y - h / 2 - f],
      //   sw: [x - w / 2 - f, y + h / 2 - f],
      //   se: [x + w / 2 - f, y + h / 2 - f],
      //   n: [x - f, y - h / 2 - f],
      //   w: [x - w / 2 - f, y - f],
      //   e: [x + w / 2 - f, y - f],
      //   s: [x - f, y + h / 2 - f]
      // }

      this.selectorParentGroup.setAttribute('display', 'inline')
      for (var dir in gipsMath) {
        let coor = gipsMath[dir]
        this.selectorGrips[dir].setAttribute('x', coor[0])
        this.selectorGrips[dir].setAttribute('y', coor[1])
      }

      this.rotateGripConnector.setAttribute('x1', x + w / 2)
      this.rotateGripConnector.setAttribute('y1', y)
      this.rotateGripConnector.setAttribute('x2', x + w / 2)
      this.rotateGripConnector.setAttribute('y2', y - 30)
      this.rotateGrip.setAttribute('cx', x + w / 2)
      this.rotateGrip.setAttribute('cy', y - 30)

      this.selectorGripsGroup.setAttribute(
        'transform',
        'rotate(' + angle + ',' + (x + w / 2) + ',' + (y + h / 2) + ')'
      )
    },
    setSelectBbox(obj, size) {
      let sizeH = obj.sizeH
      let sizeW = obj.sizeW
      let x = obj.svgPositionX
      let y = obj.svgPositionY
      let w = size.w * sizeW
      let h = size.h * sizeH
      let diffx = 0
      let diffy = 0
      if (w < 0) {
        diffx = w
      }
      if (h < 0) {
        diffy = h
      }
      this.selectBox.setAttribute('x', x + diffx)
      this.selectBox.setAttribute('y', y + diffy)
      this.selectBox.setAttribute('width', Math.abs(w))
      this.selectBox.setAttribute('height', Math.abs(h))
      // this.selectBox.setAttribute('transform',obj.angle,0,0)

      this.selectBox.setAttribute(
        'transform',
        'translate(0,0)rotate(' +
          obj.angle +
          ',' +
          (x + diffx + Math.abs(w) / 2) +
          ',' +
          (y + diffy + Math.abs(h) / 2) +
          ')scale(1)'
      )
      //  console.log(this.selectBox)
      //  this.selectBox.setBBox(size);
    },
    createSVGElement(data) {
      let svgns = 'http://www.w3.org/2000/svg'
      let node = document.createElementNS(svgns, data['element'])
      let attrs = data['attr']
      for (var i in attrs) {
        node.setAttribute(i, attrs[i])
      }
      return node
    },
    updataSelectB() {
      let toolTypeArray = this.toolTypeArray //管道、线段隐藏选中框
      if (toolTypeArray.indexOf(this.$parent.selectSvgInfo.type) != -1) {
        if (this.selectorGripsGroup && this.selectorGripsGroup.setAttribute) {
          this.selectorParentGroup.setAttribute('display', 'none')
        }
      } else {
        let doc = document.getElementById(this.$parent.selectSvgInfo.id)
        if (doc) {
          let size = { w: doc.getBBox().width, h: doc.getBBox().height }
          //  console.log(doc.getBBox())
          this.setEditxy(this.$parent.selectSvgInfo, size)
          this.setSelectBbox(this.$parent.selectSvgInfo, size)
        }
      }
    },
    initGroup() {
      // remove old selector parent group if it existed
      if (this.selectorParentGroup && this.selectorParentGroup.parentNode) {
        this.selectorParentGroup.parentNode.removeChild(this.selectorParentGroup)
      }
      let svgRoot = document.getElementById('selectorGroup')
      // create parent selector group and add it to svgroot
      this.selectorParentGroup = this.createSVGElement({
        element: 'g',
        attr: {
          id: 'selectorParentGroup',
          display: 'none'
        }
      })
      this.selectorGripsGroup = this.createSVGElement({
        element: 'g'
      })
      this.selectorParentGroup.appendChild(this.selectorGripsGroup)
      svgRoot.appendChild(this.selectorParentGroup)

      var rotateGripConnector = this.createSVGElement({
        element: 'line',
        attr: {
          id: 'selectorGrip_rotateconnector',
          stroke: '#22C',
          'stroke-width': 1 / this.stationObj.zoom
        }
      })
      this.rotateGripConnector = this.selectorGripsGroup.appendChild(rotateGripConnector)

      var rotateGrip = this.createSVGElement({
        element: 'circle',
        attr: {
          id: 'selectorGrip_rotate',
          fill: 'lime',
          r: 4 / this.stationObj.zoom,
          stroke: '#22C',
          'stroke-width': 2 / this.stationObj.zoom,
          style: `cursor:url(${this.rotatesvg}) 12 12, auto;`
        }
      })
      this.rotateGrip = this.selectorGripsGroup.appendChild(rotateGrip)

      let r = 8 / this.stationObj.zoom

      for (var dir in this.selectorGrips) {
        var grip = this.createSVGElement({
          element: 'rect',
          attr: {
            id: 'a_' + dir,
            width: r,
            height: r,
            fill: '#4F80FF',
            stroke: 'rgba(0,0,0,0)',
            'stroke-width': 1,
            style: 'cursor:' + dir + '-resize',
            'pointer-events': 'all'
          }
        })

        this.selectorGrips[dir] = this.selectorGripsGroup.appendChild(grip)
      }

      let selectBox = this.createSVGElement({
        element: 'rect',
        attr: {
          id: 'selectorGrip_box',
          fill: 'none',
          stroke: '#4F80FF',
          'stroke-width': 1 / this.stationObj.zoom,
          'shape-rendering': 'crispEdges',
          style: 'pointer-events:none',
          transform: ''
        }
      })
      this.selectBox = this.selectorParentGroup.appendChild(selectBox)
    }
  }
}
